import {
  Card,
  CardBody,
  Flex,
  HStack,
  Icon,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaBell } from "react-icons/fa";
import {
  requestDeleteFCMToken,
  requestGetFCMToken,
  requestSaveFCMToken,
} from "../lib/fcmTokenHandler";

export default function NotificationSetting() {
  const [isSwitchDisabled, setIsSwitchDisabled] = useState<boolean>(false);
  const [isNotificationChecked, setIsNotificationChecked] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const checkToken = async () => {
      const isTokenExists = await requestGetFCMToken();
      if (isTokenExists) {
        setIsNotificationChecked(true);
      } else {
        setIsNotificationChecked(false);
      }
    };
    if ("Notification" in window) {
      const permission = Notification.permission;

      if (permission === "denied") {
        setIsSwitchDisabled(true);
      } else if (permission === "granted") {
        setIsNotificationChecked(true);
        checkToken();
      }
    }
  }, []);

  const handelRequestPermission = async (isChecked: boolean) => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      if (isChecked) {
        setIsLoading(true);
        await requestSaveFCMToken(permission, toast);
        setIsLoading(false);
      } else {
        requestDeleteFCMToken(toast);
      }
    } else if (permission === "denied") {
      setIsNotificationChecked(false);
      setIsSwitchDisabled(true);
    } else {
      setIsNotificationChecked(false);
    }
  };

  return (
    <Card mt="3">
      <CardBody>
        <HStack w="100%" justifyContent={"space-between"}>
          <Text>오늘의 성찰 알림</Text>
          <Flex justifyContent={"center"} alignItems={"center"}>
            {isLoading && <Spinner size={"sm"} />}
            <Icon as={FaBell} mx="1" />
            <Switch
              isChecked={isNotificationChecked}
              isDisabled={isSwitchDisabled}
              onChange={(e) => {
                setIsNotificationChecked(e.target.checked);
                handelRequestPermission(e.target.checked);
              }}
            />
          </Flex>
        </HStack>
      </CardBody>
    </Card>
  );
}
