import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FaAngleRight, FaChevronLeft, FaLightbulb } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function TodolistGuide() {
  const navigate = useNavigate();
  return (
    <Container>
      <Flex
        py="5"
        px="1"
        onClick={() => navigate(-1)}
        _hover={{ cursor: "pointer" }}
      >
        <Icon as={FaChevronLeft} />
      </Flex>
      <VStack
        w="100%"
        justifyContent={"center"}
        alignItems={"center"}
        minHeight="calc(100vh - 128px)"
      >
        <Button
          as="a"
          href="https://blog.naver.com/beself_official/223557384855"
          target="_blank"
          w="100%"
          py="25"
        >
          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
            <GridItem
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <FaLightbulb />
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              비셀프 투두 100% 활용법
            </GridItem>
            <GridItem
              fontSize={20}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <FaAngleRight />
            </GridItem>
          </Grid>
        </Button>
        <Button
          as="a"
          href="https://youtu.be/7-rYmnP9_SQ"
          target="_blank"
          w="100%"
          py="25"
        >
          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
            <GridItem
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <FaLightbulb />
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              스마트폰 그만보고 할 일 하는 법
            </GridItem>
            <GridItem
              fontSize={20}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <FaAngleRight />
            </GridItem>
          </Grid>
        </Button>
        <Button
          as="a"
          href="https://blog.naver.com/beself_official/223296669457"
          target="_blank"
          w="100%"
          py="35"
        >
          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
            <GridItem
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <FaLightbulb />
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              <VStack>
                <Text>
                  {"≪"}나 자신이 되는 법{"≫"} 전자책 +
                </Text>
                <Text>
                  {"≪"}나를 제대로 아는 방법{"≫"} 워크북
                </Text>
              </VStack>
            </GridItem>
            <GridItem
              fontSize={20}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <FaAngleRight />
            </GridItem>
          </Grid>
        </Button>
        <Divider my="3" />
        <Button
          as="a"
          href="https://blog.naver.com/beself_official/223557503848"
          target="_blank"
          w="100%"
          py="25"
        >
          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
            <GridItem
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <FaLightbulb />
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              모바일 앱으로 사용하는 방법
            </GridItem>
            <GridItem
              fontSize={20}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <FaAngleRight />
            </GridItem>
          </Grid>
        </Button>
        <Button
          as="a"
          href="https://blog.naver.com/beself_official/223595096977"
          target="_blank"
          w="100%"
          py="25"
        >
          <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
            <GridItem
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <FaLightbulb />
            </GridItem>
            <GridItem display={"flex"} justifyContent={"center"}>
              데스크탑 앱으로 사용하는 방법
            </GridItem>
            <GridItem
              fontSize={20}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <FaAngleRight />
            </GridItem>
          </Grid>
        </Button>
      </VStack>
    </Container>
  );
}
