import { Box, useToast } from "@chakra-ui/react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import Header from "./Header";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import LoadingScreen from "./LoadingScreen";
import { onMessage } from "firebase/messaging";
import { messaging } from "../firebase";

export default function Root() {
  const [screenLoading, setScreenLoading] = useState(true);
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    // 페이지 이동 시 구글 애널리틱스에 페이지뷰 전송
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    // 포그라운드 메시지 수신 처리
    // if (messaging) {
    //   onMessage(messaging, (payload) => {
    //     toast({
    //       title: payload.data?.title,
    //       description: payload.data?.body,
    //       isClosable: true,
    //       duration: null,
    //       status: "info",
    //       position: "top",
    //     });
    //   });
    // }
  }, [location, toast]);

  const isQuestionDetailPage = useMatch("/reflection/questions/:id/upload");
  const isUploadPosExperiencePage = useMatch(
    "/reflection/experiences/positive/upload"
  );
  const isUploadNegExperiencePage = useMatch(
    "/reflection/experiences/negative/upload"
  );
  const isUploadPosQuesReflectionPage = useMatch(
    "/reflection/questions/select/positive/:pk"
  );
  const isUploadNegQuesReflectionPage = useMatch(
    "/reflection/questions/select/negative/:pk"
  );
  const isSelectQuestionPage = useMatch("/reflection/questions/select");
  const isPosQuesReflectionDetailPage = useMatch(
    "/reflection/questions/:pk/negative/:pk"
  );
  const isNegQuesReflectionDetailPage = useMatch(
    "/reflection/questions/:pk/positive/:pk"
  );
  const isPosExperienceDetailPage = useMatch(
    "/reflection/experiences/positive/:pk"
  );
  const isNegExperienceDetailPage = useMatch(
    "/reflection/experiences/negative/:pk"
  );

  const isEditPosQuesReflectionPage = useMatch(
    "/reflection/questions/:pk/negative/:pk/edit"
  );
  const isEditNegQuesReflectionPage = useMatch(
    "/reflection/questions/:pk/positive/:pk/edit"
  );
  const isEditPosExperiencePage = useMatch(
    "/reflection/experiences/positive/:pk/edit"
  );
  const isEditNegExperiencePage = useMatch(
    "/reflection/experiences/negative/:pk/edit"
  );

  const isMyDiscoveriesPage = useMatch("/my/discoveries");
  const isMyReflectionPage = useMatch("/my/reflections");

  const isReflectionGuidePage = useMatch("/reflection/guide");
  const isTodolistGuidePage = useMatch("/todolist/guide");

  const isHeaderFooterHidden =
    isQuestionDetailPage ||
    isUploadPosExperiencePage ||
    isUploadNegExperiencePage ||
    isUploadPosQuesReflectionPage ||
    isUploadNegQuesReflectionPage ||
    isSelectQuestionPage ||
    isMyDiscoveriesPage ||
    isMyReflectionPage ||
    isPosQuesReflectionDetailPage ||
    isNegQuesReflectionDetailPage ||
    isPosExperienceDetailPage ||
    isNegExperienceDetailPage ||
    isReflectionGuidePage ||
    isTodolistGuidePage ||
    isEditNegExperiencePage ||
    isEditNegQuesReflectionPage ||
    isEditPosExperiencePage ||
    isEditPosQuesReflectionPage;

  useEffect(() => {
    setTimeout(() => {
      setScreenLoading(false); // 페이지 로드 후 1초 뒤 로딩 종료
    }, 1000);
  }, []);

  return (
    <Box>
      <>
        {!isHeaderFooterHidden && <Header />}
        <Box minHeight="calc(100vh - 64px)">
          <Outlet />
        </Box>
        <ReactQueryDevtools />
        {!isHeaderFooterHidden && <Footer />}
      </>
      {screenLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          zIndex="1000"
        >
          <LoadingScreen />
        </Box>
      )}
    </Box>
  );
}
