import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Collapse,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getReflectionQuestion,
  getNegQuestionReflection,
  IEditNegQuestionReflectionSuccess,
  IEditNegQuestionReflectionError,
  IEditNegQuestionReflectionVariables,
  editNegQuestionReflection,
} from "./api";

import PositiveValues from "../components/PositiveValues";
import { useForm } from "react-hook-form";
import {
  FaAngleDown,
  FaAngleUp,
  FaChevronDown,
  FaChevronLeft,
  FaChevronUp,
} from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { INegQuesReflectionDetail } from "../types";
import NegativeEmotions from "../components/NegativeEmotions";
import NegativeValues from "../components/NegativeValues";

interface ISubQuestion {
  pk: number;
  text: string;
  order: number;
  is_positive_emotion: boolean;
  is_positive_value: boolean;
  is_negative_emotion: boolean;
  is_negative_value: boolean;
}

interface IQuestionDetail {
  title: string;
  sub_questions: ISubQuestion[];
  type: "positive" | "nagative";
}

interface IForm {
  [key: `subAnswer${number}`]: string;
  keywordNegativeValue: string;
  keywordNegativeEmotion: string;
  keywordPositiveValue: string;
  keywordActivity: string;
  title: string;
}

export default function EditNegQuestionReflection() {
  const location = useLocation();
  const isFromUpload = location.state?.isFromUpload;
  const index = isFromUpload ? 1 : 0;
  const [tabIndex, setTabIndex] = useState(index);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { register, watch, handleSubmit, reset, setValue } = useForm<IForm>();
  const { questionPk, reflectionPk } = useParams();
  const { isLoading: isQuestionLoading, data: question } =
    useQuery<IQuestionDetail>({
      queryKey: ["question", questionPk],
      queryFn: getReflectionQuestion,
    });
  const { isLoading: isReflectionLoading, data: reflection } = useQuery<
    INegQuesReflectionDetail,
    Error
  >({
    queryKey: ["questionReflection", reflectionPk],
    queryFn: getNegQuestionReflection,
  });
  useEffect(() => {
    reset({
      title: reflection?.title,
      keywordNegativeValue: reflection?.reflection_keyword.negative_value,
      keywordNegativeEmotion: reflection?.reflection_keyword.negative_emotion,
      keywordPositiveValue: reflection?.reflection_keyword.positive_value,
      keywordActivity: reflection?.reflection_keyword.activity,
    });
    reflection?.sub_answers.forEach((subAnswer) => {
      setValue(`subAnswer${subAnswer.sub_question_pk}`, subAnswer.text);
    });
  }, [reflection, reset, setValue]);
  const navigate = useNavigate();
  const toast = useToast();
  const mutation = useMutation<
    IEditNegQuestionReflectionSuccess,
    IEditNegQuestionReflectionError,
    IEditNegQuestionReflectionVariables
  >({
    mutationFn: editNegQuestionReflection,
    onSuccess: () => {
      toast({
        title: `성찰이 업데이트 되었습니다!`,
        status: "success",
        position: "top",
        isClosable: true,
        duration: 3000,
      });
      navigate(`/reflection/questions/${questionPk}/negative/${reflectionPk}`);
    },
    onError: (error) => console.log(error),
  });
  const highlightColor = useColorModeValue("yellow.100", "green.800");
  const inputNegativeValue = watch("keywordNegativeValue");
  const inputNegativeEmotion = watch("keywordNegativeEmotion");
  const inputPositiveValue = watch("keywordPositiveValue");
  const inputActivity = watch("keywordActivity");

  const pathType = location.pathname.split("/")[4];
  useEffect(() => {
    if (question) {
      if (question.type !== pathType) {
        navigate("/reflection/questions");
      }
    }
  }, [question, navigate, pathType]);

  const onSubmit = (formData: IForm) => {
    if (reflectionPk) {
      mutation.mutate({ formData, pk: parseInt(reflectionPk) });
    }
  };

  const isLoading = isQuestionLoading || isReflectionLoading;

  const {
    isOpen: isCancelAlertOpen,
    onOpen: onCancelAlertOpen,
    onClose: onCancelAlertClose,
  } = useDisclosure();
  const cancelRef = React.useRef(null);

  const handleCancel = () => {
    onCancelAlertClose();
    navigate(`/reflection/questions/${questionPk}/negative/${reflectionPk}`);
  };

  interface IWatchSubAnswer {
    text: string;
    sub_question_pk: number;
  }

  let subAnswerStates: IWatchSubAnswer[] = [];

  reflection?.sub_answers.forEach((subAnswer) => {
    const subAnswerState = watch(`subAnswer${subAnswer.sub_question_pk}`);
    subAnswerStates = [
      ...subAnswerStates,
      {
        text: subAnswerState,
        sub_question_pk: subAnswer.sub_question_pk,
      },
    ];
  });

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Container mb="20">
      <Flex>
        <Flex
          py="5"
          px="1"
          onClick={onCancelAlertOpen}
          _hover={{ cursor: "pointer" }}
        >
          <Icon as={FaChevronLeft} />
        </Flex>
      </Flex>
      <AlertDialog
        isCentered
        isOpen={isCancelAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCancelAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {reflection?.title} 닫기
            </AlertDialogHeader>
            <AlertDialogBody>저장하지 않고 닫으시겠습니까?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCancelAlertClose}>
                취소
              </Button>
              <Button colorScheme="red" onClick={handleCancel} ml={3}>
                닫기
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      {isLoading ? (
        "로딩중..."
      ) : (
        <VStack>
          {question ? (
            <>
              <Tabs
                w="100%"
                isFitted
                index={tabIndex}
                onChange={(index) => setTabIndex(index)}
              >
                <TabList>
                  {question?.sub_questions.map((sub_question, index) => (
                    <Tab key={index}>{index + 1}</Tab>
                  ))}
                  <Tab>{question?.sub_questions.length + 1}</Tab>
                </TabList>
                <TabPanels as="form" onSubmit={handleSubmit(onSubmit)}>
                  {question?.sub_questions.map((sub_question, index) => (
                    <TabPanel key={index} px="0">
                      <VStack>
                        {tabIndex !== 0 && (
                          <Card w="100%">
                            <CardBody py="3">
                              <Collapse
                                in={!isCollapsed}
                                animateOpacity
                                unmountOnExit
                              >
                                <VStack alignItems={"flex-start"}>
                                  {question?.sub_questions
                                    .slice(0, tabIndex)
                                    .map((subQuestion, index) => (
                                      <Box key={index} w="100%">
                                        <Text>
                                          {index + 1}. {subQuestion.text}
                                        </Text>
                                        {subAnswerStates
                                          .filter(
                                            (subAnswerState) =>
                                              subAnswerState.sub_question_pk ===
                                              subQuestion.pk
                                          )
                                          .map((subAnswerState, index) => (
                                            <Text
                                              key={index}
                                              mt="1"
                                              fontWeight={700}
                                            >
                                              → {subAnswerState.text}
                                            </Text>
                                          ))}
                                        <Divider my="1" />
                                      </Box>
                                    ))}
                                </VStack>
                              </Collapse>
                              <VStack w="100%">
                                <Button
                                  variant={"ghost"}
                                  onClick={toggleCollapse}
                                >
                                  <Icon
                                    as={
                                      isCollapsed ? FaChevronDown : FaChevronUp
                                    }
                                  />
                                </Button>
                              </VStack>
                            </CardBody>
                          </Card>
                        )}
                        <Card w="100%">
                          <CardBody px="3">
                            <VStack>
                              <Text
                                textAlign="center"
                                fontSize={18}
                                fontWeight={700}
                                wordBreak="keep-all"
                                whiteSpace="normal"
                              >
                                {sub_question.text}
                              </Text>
                              <Textarea
                                h="200px"
                                resize="vertical"
                                {...register(`subAnswer${sub_question.pk}`)}
                              />
                              {sub_question.is_negative_emotion ? (
                                <NegativeEmotions />
                              ) : sub_question.is_negative_value ? (
                                <NegativeValues />
                              ) : sub_question.is_positive_value ? (
                                <PositiveValues />
                              ) : null}
                            </VStack>
                          </CardBody>
                        </Card>
                        <Grid w="100%" gridTemplateColumns={"1fr 1fr"} gap="2">
                          {index === 0 ? (
                            <Box></Box>
                          ) : (
                            <Button
                              onClick={() => setTabIndex(index - 1)}
                              fontSize="24"
                            >
                              ←
                            </Button>
                          )}

                          <Button
                            onClick={() => setTabIndex(index + 1)}
                            fontSize="24"
                          >
                            →
                          </Button>
                        </Grid>
                        <Grid w="100%" templateColumns="1fr 1fr" gap={2}>
                          <Button
                            type="submit"
                            isLoading={mutation.isPending}
                            colorScheme="blue"
                            variant="outline"
                            width="100%"
                            borderRadius="md"
                          >
                            저장
                          </Button>
                          <Button
                            width="100%"
                            borderRadius="md"
                            colorScheme="blue"
                            variant="outline"
                            onClick={() =>
                              setTabIndex(question.sub_questions.length)
                            }
                          >
                            성찰 정리
                          </Button>
                        </Grid>
                      </VStack>
                    </TabPanel>
                  ))}
                  <TabPanel px="0">
                    <VStack>
                      {tabIndex !== 0 && (
                        <Card w="100%">
                          <CardBody py="3">
                            <Collapse
                              in={!isCollapsed}
                              animateOpacity
                              unmountOnExit
                            >
                              <VStack alignItems={"flex-start"}>
                                {question?.sub_questions
                                  .slice(0, tabIndex)
                                  .map((subQuestion, index) => (
                                    <Box key={index} w="100%">
                                      <Text>
                                        {index + 1}. {subQuestion.text}
                                      </Text>
                                      {subAnswerStates
                                        .filter(
                                          (subAnswerState) =>
                                            subAnswerState.sub_question_pk ===
                                            subQuestion.pk
                                        )
                                        .map((subAnswerState, index) => (
                                          <Text
                                            key={index}
                                            mt="1"
                                            fontWeight={700}
                                          >
                                            → {subAnswerState.text}
                                          </Text>
                                        ))}
                                      <Divider my="1" />
                                    </Box>
                                  ))}
                              </VStack>
                            </Collapse>
                            <VStack w="100%">
                              <Button
                                variant={"ghost"}
                                onClick={toggleCollapse}
                              >
                                <Icon
                                  as={isCollapsed ? FaChevronDown : FaChevronUp}
                                />
                              </Button>
                            </VStack>
                          </CardBody>
                        </Card>
                      )}
                      <Card w="100%">
                        <CardBody>
                          <VStack>
                            <Text
                              fontSize={18}
                              fontWeight={700}
                              textAlign={"center"}
                            >
                              다음의 4가지 키워드를 완성해보세요!
                            </Text>
                            <Divider />
                            <FormControl>
                              <FormLabel>나에게 해로운 부정가치</FormLabel>
                              <Input
                                textAlign={"center"}
                                placeholder="ex) 정체됨, 퇴보"
                                {...register("keywordNegativeValue")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel>부정가치에 내가 느끼는 감정</FormLabel>
                              <Input
                                textAlign={"center"}
                                placeholder="ex) 답답함, 무기력함"
                                {...register("keywordNegativeEmotion")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel>나에게 의미있는 긍정가치</FormLabel>
                              <Input
                                textAlign={"center"}
                                placeholder="ex) 성장, 변화"
                                {...register("keywordPositiveValue")}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel>
                                긍정가치를 실천할 수 있는 활동들
                              </FormLabel>
                              <Input
                                textAlign={"center"}
                                placeholder="ex) 독서, 글쓰기, 건설적인 토론"
                                {...register("keywordActivity")}
                              />
                            </FormControl>
                          </VStack>
                        </CardBody>
                      </Card>
                      <Card w="100%">
                        <CardBody>
                          <VStack>
                            <Text
                              fontSize={20}
                              fontWeight={700}
                              textAlign={"center"}
                            >
                              나의 성찰
                            </Text>
                            <Divider />
                            <Text textAlign={"center"} lineHeight={2}>
                              나는{" "}
                              <Text
                                as="span"
                                my="3"
                                fontSize={18}
                                fontWeight={"700"}
                                bg={highlightColor}
                              >
                                {inputNegativeValue
                                  ? inputNegativeValue
                                  : "정체됨, 퇴보"}
                              </Text>{" "}
                              에{" "}
                              <Text
                                as="span"
                                my="3"
                                fontSize={18}
                                fontWeight={"700"}
                                bg={highlightColor}
                              >
                                {inputNegativeEmotion
                                  ? inputNegativeEmotion
                                  : "답답함, 무기력함"}
                              </Text>{" "}
                              을(를) 느낀다.
                            </Text>
                          </VStack>
                        </CardBody>
                      </Card>
                      <Card w="100%">
                        <CardBody>
                          <VStack>
                            <Text
                              fontSize={20}
                              fontWeight={700}
                              textAlign={"center"}
                            >
                              나의 추구
                            </Text>
                            <Divider />
                            <Text textAlign={"center"} lineHeight={2}>
                              나는{" "}
                              <Text
                                as="span"
                                my="3"
                                fontSize={18}
                                fontWeight={"700"}
                                bg={highlightColor}
                              >
                                {inputActivity
                                  ? inputActivity
                                  : "독서, 글쓰기, 건설적인 토론"}
                              </Text>{" "}
                              을(를) 통해{" "}
                              <Text
                                as="span"
                                my="3"
                                fontSize={18}
                                fontWeight={"700"}
                                bg={highlightColor}
                              >
                                {inputPositiveValue
                                  ? inputPositiveValue
                                  : "성장, 변화"}
                              </Text>{" "}
                              을(를) 추구할 것이다.
                            </Text>
                          </VStack>
                        </CardBody>
                      </Card>
                      <Card w="100%">
                        <CardBody>
                          <VStack>
                            <Text
                              fontSize={18}
                              fontWeight={700}
                              textAlign={"center"}
                            >
                              이번 성찰의 제목을 작성해주세요!
                            </Text>
                            <Input
                              textAlign={"center"}
                              placeholder="부정경험 성찰"
                              {...register("title")}
                            />
                          </VStack>
                        </CardBody>
                      </Card>
                      <Grid w="100%" gridTemplateColumns={"1fr 1fr"} gap="2">
                        <Button
                          onClick={() =>
                            setTabIndex(question.sub_questions.length - 1)
                          }
                          fontSize="24"
                        >
                          ←
                        </Button>
                        <Button type="submit" isLoading={mutation.isPending}>
                          저장
                        </Button>
                      </Grid>
                    </VStack>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          ) : null}
        </VStack>
      )}
    </Container>
  );
}
