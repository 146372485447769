import {
  Box,
  Button,
  Card,
  Container,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaAngleRight, FaLightbulb, FaPen } from "react-icons/fa";
import TodoModal from "../components/TodoModal";
import TodoCard from "../components/TodoCard";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IAddIdentityError,
  IAddIdentitySuccess,
  IAddIdentityVariables,
  IAddTodoGroupError,
  IAddTodoGroupSuccess,
  IAddTodoGroupVariables,
  IUpdateIdentityError,
  IUpdateIdentitySuccess,
  IUpdateIdentityVariables,
  IUpdateTaskOrderError,
  IUpdateTaskOrderSuccess,
  IUpdateTaskOrderVariables,
  IUpdateTodoGroupsOrderError,
  IUpdateTodoGroupsOrderSuccess,
  IUpdateTodoGroupsOrderVariables,
  addIdentity,
  addTodoGroup,
  getIdentity,
  getTodoGroups,
  updateIdentity,
  updateTaskOrder,
  updateTodoGroupsOrder,
} from "./api";
import useUser from "../lib/useUser";
import { Link, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import TodoGroupSkeleton from "../components/TodoGroupSkeleton";
import EmailVerificationProtected from "../components/EmailVerificationProtected";
import TermsAgreementProtected from "../components/TermsAgreementProtected";

export interface ITask {
  pk: number;
  group: number;
  text: string;
  completed: boolean;
  order: number;
  condition: string;
  total_subtasks: number;
}

export interface ITodoGroup {
  pk: number;
  name: string;
  order: number;
  tasks: ITask[];
}

export interface IIdentity {
  pk: number;
  name: string;
}

export default function Todolist() {
  const navigate = useNavigate();
  const { userLoading, isLoggedIn } = useUser();
  const [identity, setIdentity] = useState<string>("");
  const [todoGroups, setTodoGroups] = useState<ITodoGroup[]>([]);
  const [tasksForModal, setTasksForModal] = useState<ITask[]>([]);
  const [currentTodoGroupPk, setCurrentTodoGroupPk] = useState<number | null>(
    null
  );
  const [currentTaskPk, setCurrentTaskPk] = useState<number | null>(null);
  const [isGroupEditing, setIsGroupEditing] = useState(false);
  const [isAnyDragging, setIsAnyDragging] = useState(false);
  const { isLoading: isTodoGroupLoading, data: todoGroupData } = useQuery<
    ITodoGroup[],
    Error
  >({
    queryKey: ["todos"],
    queryFn: getTodoGroups,
  });

  const { isLoading: isIdentityLoading, data: identityData } = useQuery<
    IIdentity,
    Error
  >({ queryKey: ["identity"], queryFn: getIdentity, retry: false });
  const queryClient = useQueryClient();
  const addTodoGroupMutation = useMutation<
    IAddTodoGroupSuccess,
    IAddTodoGroupError,
    IAddTodoGroupVariables
  >({
    mutationFn: addTodoGroup,
    onSuccess: (data) => queryClient.refetchQueries({ queryKey: ["todos"] }),
    onError: (error) => console.log(error),
  });

  const updateTodoGroupsOrderMutation = useMutation<
    IUpdateTodoGroupsOrderSuccess,
    IUpdateTodoGroupsOrderError,
    IUpdateTodoGroupsOrderVariables
  >({
    mutationFn: updateTodoGroupsOrder,
    onSuccess: (data) => console.log(data),
    onError: (error) => console.log(error),
  });

  const updateIdentityMutation = useMutation<
    IUpdateIdentitySuccess,
    IUpdateIdentityError,
    IUpdateIdentityVariables
  >({
    mutationFn: updateIdentity,
    onSuccess: (data) => queryClient.refetchQueries({ queryKey: ["identity"] }),
    onError: (error) => console.log(error),
  });

  const addIdentityMutation = useMutation<
    IAddIdentitySuccess,
    IAddIdentityError,
    IAddIdentityVariables
  >({
    mutationFn: addIdentity,
    onSuccess: (data) => queryClient.refetchQueries({ queryKey: ["identity"] }),
    onError: (error) => console.log(error),
  });

  const updateTaskOrderMutation = useMutation<
    IUpdateTaskOrderSuccess,
    IUpdateTaskOrderError,
    IUpdateTaskOrderVariables
  >({
    mutationFn: updateTaskOrder,
    onError: (error) => console.log(error),
  });

  useEffect(() => {
    if (identityData) {
      setIdentity(identityData.name);
    }
  }, [identityData]);

  useEffect(() => {
    if (todoGroupData) {
      setTodoGroups(() => todoGroupData);
    }
  }, [todoGroupData]);

  const {
    isOpen: isModalOpen,
    onClose: onModalClose,
    onOpen: onModalOpen,
  } = useDisclosure();

  const handleEditToggle = () => {
    setIsGroupEditing((prev: boolean) => !prev);
  };

  const handleOpenModal = (
    taskPk: number | null,
    todoGroupPk: number | null
  ) => {
    setCurrentTaskPk(taskPk);
    setCurrentTodoGroupPk(todoGroupPk);
    onModalOpen();
  };

  const handleAddTodoGroup = () => {
    if (!isLoggedIn) {
      navigate("/login");
    } else {
      let newOrder = 1; // 기본 값 설정

      if (todoGroupData && todoGroupData.length > 0) {
        // 현재 그룹들의 order 값 가져오기
        const orders = todoGroupData.map((group) => group.order);
        // 최대 order 값을 계산
        const maxOrder = Math.max(...orders);
        // 새로운 그룹의 order 값 설정
        newOrder = maxOrder + 1;
      }

      // mutate
      addTodoGroupMutation.mutate(
        { name: "새 그룹", order: newOrder },
        {
          onError: () => {
            // 에러 시 이전 상태로 롤백
            setTodoGroups(todoGroups);
          },
        }
      );
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { source, destination, type } = result;

    if (type === "group") {
      // group DnD
      const prevTodoGroups: ITodoGroup[] = [...todoGroups]; // fetch 실패 시 원상복구용
      // 기존 TodoGroups 가져오기
      const reorderedTodoGroups = [...todoGroups];
      // 드래그된 요소 제거 후 removed 변수에 반환
      const [removed] = reorderedTodoGroups.splice(source.index, 1);
      // 드롭된 위치에 removed 삽입, 0은 아무것도 제거하지 않음을 의미함.
      reorderedTodoGroups.splice(destination.index, 0, removed); //
      // 새로운 순서에 맞게 order 업데이트
      const updatedTodoGroups = reorderedTodoGroups.map(
        (todoGroups, index) => ({
          ...todoGroups,
          order: index + 1, // 새로운 order 값 할당
        })
      );

      setTodoGroups(updatedTodoGroups);

      updateTodoGroupsOrderMutation.mutate(
        { updatedTodoGroups },
        {
          onError: () => {
            setTodoGroups(prevTodoGroups); // mutation 에러일 경우 원래대로 복귀
          },
        }
      );
    } else if (type === "task") {
      // task Dnd
      // 유효성 검사
      const sourceGroup = todoGroups.find(
        (group) => group.pk === parseInt(source.droppableId)
      );
      const destinationGroup = todoGroups.find(
        (group) => group.pk === parseInt(destination.droppableId)
      );
      if (!sourceGroup || !destinationGroup) return;

      if (source.droppableId === destination.droppableId) {
        // 같은 그룹 내에서 이동

        // 기존 tasks 가져오기
        const reorderedTasks = queryClient.getQueryData<ITask[]>([
          "tasks",
          sourceGroup.pk,
        ]);
        if (reorderedTasks) {
          // 드래그된 요소 제거 후 removed 변수에 반환
          const [removed] = reorderedTasks.splice(source.index, 1);
          // 드롭된 위치에 removed 삽입, 0은 아무것도 제거하지 않음을 의미함.
          reorderedTasks.splice(destination.index, 0, removed);
          // 새로운 순서에 맞게 order 업데이트
          const updatedTasks = reorderedTasks.map((task, index) => ({
            ...task,
            order: index + 1,
          }));

          queryClient.setQueryData<ITask[]>(
            ["tasks", sourceGroup.pk],
            () => updatedTasks
          );

          updateTaskOrderMutation.mutate(
            { updatedTasks },
            {
              onError: () => {
                queryClient.refetchQueries({
                  queryKey: ["tasks", sourceGroup.pk],
                });
              },
            }
          );
        }
      } else {
        // 다른 그룹으로 이동

        const sourceTasks = queryClient.getQueryData<ITask[]>([
          "tasks",
          sourceGroup.pk,
        ]);

        const destinationTasks = queryClient.getQueryData<ITask[]>([
          "tasks",
          destinationGroup.pk,
        ]);

        // tasks 30개 이상이면 return
        if ((destinationTasks?.length ?? 0) >= 30) return;

        if (sourceTasks && destinationTasks) {
          // Remove task from source group
          const [movedTask] = sourceTasks.splice(source.index, 1);

          // Add task to destination group
          destinationTasks.splice(destination.index, 0, movedTask);

          // Update orders
          const updatedSourceTasks = sourceTasks.map((task, index) => ({
            ...task,
            order: index + 1,
          }));
          const updatedDestinationTasks = destinationTasks.map(
            (task, index) => ({
              ...task,
              order: index + 1,
              group: destinationGroup.pk, // 새 그룹 정보 추가
            })
          );

          queryClient.setQueryData<ITask[]>(
            ["tasks", sourceGroup.pk],
            (old) => updatedSourceTasks
          );

          queryClient.setQueryData<ITask[]>(
            ["tasks", destinationGroup.pk],
            (old) => updatedDestinationTasks
          );

          updateTaskOrderMutation.mutate(
            {
              updatedTasks: [...updatedSourceTasks, ...updatedDestinationTasks],
            },
            {
              onError: () => {
                // fetch 실패 시 원래대로 복귀
                queryClient.refetchQueries({
                  queryKey: ["tasks", sourceGroup.pk],
                });
                queryClient.refetchQueries({
                  queryKey: ["tasks", destinationGroup.pk],
                });
              },
            }
          );
        }
      }
    }

    setIsAnyDragging(false);
  };

  const currentTodoGroup = todoGroups.find(
    (group) => group.pk === currentTodoGroupPk
  );

  const handleUpdateIdentity = (name: string) => {
    if (!isLoggedIn) {
      setIdentity("");
      navigate("/login");
    } else if (identityData) {
      updateIdentityMutation.mutate({ name, pk: identityData.pk });
    } else {
      addIdentityMutation.mutate({ name });
    }
  };

  const { colorMode } = useColorMode();

  return (
    <TermsAgreementProtected>
      <EmailVerificationProtected>
        <Container>
          {!userLoading ? (
            <VStack w="100%">
              {isIdentityLoading ? (
                <Flex my="3" justifyContent={"center"} w="100%">
                  <Skeleton width="50%" h="35" />
                </Flex>
              ) : (
                <HStack
                  fontSize={{ base: "2xl", sm: "3xl" }}
                  justifyContent={"center"}
                  w="100%"
                >
                  <Text whiteSpace="nowrap">나는</Text>
                  <Editable
                    whiteSpace="nowrap"
                    value={identity}
                    onChange={(value) => setIdentity(value)}
                    placeholder="____" // value가 없을 경우 input이 사라지는 것을 방지
                  >
                    <EditablePreview cursor={"pointer"} />
                    <EditableInput
                      onBlur={(e) => handleUpdateIdentity(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevents the default Enter key behavior
                          (e.target as HTMLInputElement).blur(); // Triggers the onBlur event
                        }
                      }}
                      textAlign={"center"}
                      autoComplete="off"
                    />
                  </Editable>
                </HStack>
              )}
              <Card w="100%">
                <Link to="/todolist/guide">
                  <Button w="100%" py="25" variant="ghost">
                    <Grid w="100%" gridTemplateColumns={"1fr 5fr 1fr"}>
                      <GridItem
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <FaLightbulb />
                      </GridItem>
                      <GridItem display={"flex"} justifyContent={"center"}>
                        <Text>비셀프 투두 완벽 가이드</Text>
                      </GridItem>
                      <GridItem
                        fontSize={20}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <FaAngleRight />
                      </GridItem>
                    </Grid>
                  </Button>
                </Link>
              </Card>
              <DragDropContext
                onBeforeDragStart={() => setIsAnyDragging(true)}
                onDragEnd={onDragEnd}
              >
                <VStack w="100%">
                  <Droppable droppableId="todo group" type="group">
                    {(provided) => (
                      <VStack
                        w="100%"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {isTodoGroupLoading ? (
                          <>
                            <TodoGroupSkeleton />
                            <TodoGroupSkeleton />
                            <TodoGroupSkeleton />
                            <TodoGroupSkeleton />
                            <TodoGroupSkeleton />
                          </>
                        ) : (
                          todoGroups?.map((todoGroup, index) => (
                            <Draggable
                              key={todoGroup.pk}
                              draggableId={`todo group draggable-${todoGroup.pk}`}
                              index={index}
                            >
                              {(provided) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  w="100%"
                                >
                                  <TodoCard
                                    setTasksForModal={setTasksForModal}
                                    key={todoGroup.pk}
                                    todoGroup={todoGroup}
                                    handleOpenModal={handleOpenModal}
                                    isGroupEditing={isGroupEditing}
                                    groupProvided={provided}
                                    isAnyDragging={isAnyDragging}
                                  />
                                </Box>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </VStack>
                    )}
                  </Droppable>
                </VStack>
              </DragDropContext>
              <Grid gap="5" templateColumns="repeat(3, 1fr)" w="100%">
                <GridItem>
                  <Box></Box>
                </GridItem>
                <GridItem display={"flex"} justifyContent={"center"}>
                  {(todoGroupData?.length ?? 0) >= 20 ? null : (
                    <Button
                      isLoading={addTodoGroupMutation.isPending}
                      spinner={
                        <BeatLoader
                          size={8}
                          color={colorMode === "light" ? "black" : "white"}
                        />
                      }
                      justifyItems={"center"}
                      onClick={handleAddTodoGroup}
                    >
                      그룹 추가
                    </Button>
                  )}
                </GridItem>
                <GridItem display={"flex"} justifyContent={"flex-end"}>
                  {todoGroupData?.length !== 0 ? (
                    <Button
                      onClick={handleEditToggle}
                      colorScheme={isGroupEditing ? "yellow" : "gray"}
                    >
                      <FaPen />
                    </Button>
                  ) : null}
                </GridItem>
              </Grid>
              {isModalOpen && (
                <TodoModal
                  tasks={tasksForModal}
                  currentTaskPk={currentTaskPk}
                  currentTodoGroupPk={currentTodoGroupPk}
                  isOpen={isModalOpen}
                  onClose={onModalClose}
                />
              )}
            </VStack>
          ) : null}
        </Container>
      </EmailVerificationProtected>
    </TermsAgreementProtected>
  );
}
