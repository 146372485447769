import { initializeApp } from "firebase/app";
import { getMessaging, isSupported, Messaging } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

import { checkFcmToken, requestSaveFCMToken } from "./lib/fcmTokenHandler";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
};

let app: ReturnType<typeof initializeApp> | null = null;
export let messaging: Messaging | null = null;

(async () => {
  const supported = await isSupported();
  if (supported) {
    app = initializeApp(firebaseConfig);
    messaging = getMessaging(app);
    getAnalytics(app);
    checkFcmToken();
  }
})();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .getRegistration("/firebase-cloud-messaging-push-scope/")
    .then((registration) => {
      if (registration) {
        // 업데이트 이벤트 리스너 등록
        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;
          if (newWorker) {
            newWorker.addEventListener("statechange", () => {
              if (newWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  console.log("Service Worker updated successfully.");
                  const permission = Notification.permission;
                  requestSaveFCMToken(permission, null);
                } else {
                  console.log(
                    "New Service Worker installed, but not yet controlling."
                  );
                  const permission = Notification.permission;
                  requestSaveFCMToken(permission, null);
                }
              }
            });
          }
        });

        registration.update();
      } else {
        console.log("No Service Worker found for this scope.");
      }
    });
}
