import {
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  Skeleton,
  Spinner,
  Switch,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import EmailVerificationProtected from "../components/EmailVerificationProtected";
import TermsAgreementProtected from "../components/TermsAgreementProtected";
import {
  FaAngleRight,
  FaArrowRight,
  FaBell,
  FaFeatherAlt,
  FaLeaf,
  FaLightbulb,
  FaQuoteLeft,
} from "react-icons/fa";
import { useEffect, useState } from "react";
import { isSupported } from "firebase/messaging";
import useUser from "../lib/useUser";
import { useQuery } from "@tanstack/react-query";
import { getDailyReflection } from "./api";
import { requestSaveFCMToken } from "../lib/fcmTokenHandler";

interface IDailyReflection {
  reflection_type: "question" | "experience";
  reflection_mood: "positive" | "negative";
  text: string;
  question: number;
}

export default function Home() {
  const { isLoggedIn, userLoading } = useUser();
  const [pushPermission, setPushPermission] = useState<string>();
  const [isNotificationChecked, setIsNotificationChecked] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fcmSupported, setFcmSupported] = useState(false);
  const toast = useToast();

  const { isLoading: isDailyReflectionLoading, data: dailyReflectionData } =
    useQuery<IDailyReflection, Error>({
      queryKey: ["dailyReflection"],
      queryFn: getDailyReflection,
    });

  useEffect(() => {
    const checkSupport = async () => {
      const supported = await isSupported();
      setFcmSupported(supported);
    };
    checkSupport();
    if ("Notification" in window) {
      const permission = Notification.permission;
      setPushPermission(permission);
    }
  }, []);

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted" || permission === "denied") {
      setIsLoading(true);
      await requestSaveFCMToken(permission, toast);
      setIsLoading(false);
      setPushPermission(permission);
    } else {
      setIsNotificationChecked(false);
    }
  };

  const dailyReflectionUrl =
    dailyReflectionData?.reflection_type === "question"
      ? `reflection/questions/select/${dailyReflectionData.reflection_mood}/${dailyReflectionData.question}`
      : "reflection/experiences/positive/upload";

  return (
    <TermsAgreementProtected>
      <EmailVerificationProtected>
        <Container minHeight="calc(100vh - 128px)">
          <VStack w="100%" justifyContent={"center"} alignItems={"center"}>
            <Card w="100%">
              <CardBody px="3">
                <VStack w="100%">
                  <Grid w="100%" gridTemplateColumns={"1fr 1fr 1fr"}>
                    <GridItem></GridItem>
                    <GridItem
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Icon fontSize={"18"} as={FaQuoteLeft} />
                    </GridItem>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      {pushPermission === "granted" ||
                      pushPermission === "denied" ||
                      userLoading ||
                      !isLoggedIn ||
                      !fcmSupported ? null : (
                        <>
                          {isLoading && <Spinner size={"sm"} />}
                          <Icon color={"yellow.400"} as={FaBell} mx="0.5" />
                          <Switch
                            isChecked={isNotificationChecked}
                            onChange={(e) => {
                              setIsNotificationChecked(e.target.checked);
                              requestPermission();
                            }}
                          />
                        </>
                      )}
                    </GridItem>
                  </Grid>
                  <Link to={dailyReflectionUrl} style={{ width: "100%" }}>
                    <VStack>
                      {isDailyReflectionLoading ? (
                        <Skeleton w="90%" h="7" my="3" />
                      ) : (
                        <Text
                          fontWeight={"700"}
                          fontSize={"large"}
                          my="3"
                          textAlign={"center"}
                        >
                          {dailyReflectionData?.text}
                        </Text>
                      )}
                      <Icon as={FaArrowRight} />
                    </VStack>
                  </Link>
                </VStack>
              </CardBody>
            </Card>
            <Divider my="3" />
            <HStack w="100%">
              <Link to="/reflection/questions" style={{ width: "100%" }}>
                <Card w="100%">
                  <Button
                    variant={"ghost"}
                    w="100%"
                    py="25"
                    colorScheme="yellow"
                  >
                    <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                      <GridItem
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <FaFeatherAlt />
                      </GridItem>
                      <GridItem display={"flex"} justifyContent={"center"}>
                        질문성찰
                      </GridItem>
                      <GridItem
                        fontSize={20}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <FaAngleRight />
                      </GridItem>
                    </Grid>
                  </Button>
                </Card>
              </Link>
              <Link to="/reflection/experiences" style={{ width: "100%" }}>
                <Card w="100%">
                  <Button
                    variant={"ghost"}
                    w="100%"
                    py="25"
                    colorScheme="green"
                  >
                    <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                      <GridItem
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        <FaLeaf />
                      </GridItem>
                      <GridItem display={"flex"} justifyContent={"center"}>
                        경험성찰
                      </GridItem>
                      <GridItem
                        fontSize={20}
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                      >
                        <FaAngleRight />
                      </GridItem>
                    </Grid>
                  </Button>
                </Card>
              </Link>
            </HStack>
            <Link to="/my/discoveries" style={{ width: "100%" }}>
              <Card w="100%">
                <Button variant={"ghost"} w="100%" py="25">
                  <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <FaLightbulb />
                    </GridItem>
                    <GridItem display={"flex"} justifyContent={"center"}>
                      나의 발견
                    </GridItem>
                    <GridItem
                      fontSize={20}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <FaAngleRight />
                    </GridItem>
                  </Grid>
                </Button>
              </Card>
            </Link>
            <Link to="/my/reflections" style={{ width: "100%" }}>
              <Card w="100%">
                <Button variant={"ghost"} w="100%" py="25">
                  <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <FaLightbulb />
                    </GridItem>
                    <GridItem display={"flex"} justifyContent={"center"}>
                      나의 성찰
                    </GridItem>
                    <GridItem
                      fontSize={20}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <FaAngleRight />
                    </GridItem>
                  </Grid>
                </Button>
              </Card>
            </Link>
            <Link style={{ width: "100%" }} to={"/todolist"}>
              <Card w="100%">
                <Button variant={"ghost"} w="100%" py="25">
                  <Grid w="100%" gridTemplateColumns={"1fr 3fr 1fr"}>
                    <GridItem
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <FaLightbulb />
                    </GridItem>
                    <GridItem display={"flex"} justifyContent={"center"}>
                      나의 실천
                    </GridItem>
                    <GridItem
                      fontSize={20}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      alignItems={"center"}
                    >
                      <FaAngleRight />
                    </GridItem>
                  </Grid>
                </Button>
              </Card>
            </Link>
          </VStack>
        </Container>
      </EmailVerificationProtected>
    </TermsAgreementProtected>
  );
}
