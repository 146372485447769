import { UseToastOptions } from "@chakra-ui/react";
import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";
import { deleteFCMToken, getFCMToken, saveFCMToken } from "../routes/api";
import { AxiosError } from "axios";

export const requestSaveFCMToken = async (
  permission: string,
  toast: ((options: UseToastOptions) => void) | null
) => {
  if (permission === "granted" && messaging) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      if (currentToken) {
        // 서버에 FCM 토큰 전송
        await saveFCMToken(currentToken);
        localStorage.setItem("fcmToken", currentToken);
        console.log("서버에 FCM 토큰 저장 성공");
        if (toast) {
          toast({
            title: "설정이 완료되었습니다!",
            isClosable: true,
            duration: 3000,
            status: "success",
            position: "top",
          });
        }
      } else {
        console.log("FCM 토큰을 가져올 수 없습니다.");
        if (toast) {
          toast({
            title: "푸시 알림 설정 오류",
            description: "프로필 설정에서 다시 시도해주세요.",
            isClosable: true,
            duration: 3000,
            status: "error",
            position: "top",
          });
        }
      }
    } catch (error) {
      console.error("FCM 토큰 처리 중 오류 발생:", error);
      if (toast) {
        toast({
          title: "푸시 알림 설정 오류",
          description: "프로필 설정에서 다시 시도해주세요.",
          isClosable: true,
          duration: 3000,
          status: "error",
          position: "top",
        });
      }
    }
  } else {
    console.log("알림 권한이 거부되었습니다.");
  }
};

export const requestDeleteFCMToken = async (
  toast: (options: UseToastOptions) => void
) => {
  if (messaging) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      if (currentToken) {
        // 서버에 FCM 토큰 전송
        await deleteFCMToken(currentToken);
        console.log("FCM 토큰 삭제 완료");
      } else {
        console.log("FCM 토큰을 가져올 수 없습니다.");
        if (toast) {
          toast({
            title: "오류가 발생하였습니다. 다시 시도해주세요.",
            isClosable: true,
            duration: 3000,
            status: "error",
            position: "top",
          });
        }
      }
    } catch (error) {
      console.error("FCM 토큰 처리 중 오류 발생:", error);
      if (toast) {
        toast({
          title: "오류가 발생하였습니다. 다시 시도해주세요.",
          isClosable: true,
          duration: 3000,
          status: "error",
          position: "top",
        });
      }
    }
  }
};

export const requestGetFCMToken = async () => {
  if (messaging) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      if (currentToken) {
        // 서버에 FCM 토큰 전송
        try {
          await getFCMToken(currentToken);
          return true;
        } catch (error) {
          if (error instanceof AxiosError) {
            return false;
          }
        }
      } else {
        console.log("FCM 토큰을 가져올 수 없습니다.");
      }
    } catch (error) {
      console.error("FCM 토큰 처리 중 오류 발생:", error);
    }
  }
};

export const checkFcmToken = async () => {
  const localStorageFcmToken = localStorage.getItem("fcmToken");
  if (localStorageFcmToken && messaging) {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      });
      if (currentToken && currentToken !== localStorageFcmToken) {
        localStorage.setItem("fcmToken", currentToken);
        await saveFCMToken(currentToken);
        await deleteFCMToken(localStorageFcmToken);
        console.log("FCM 토큰 변경 완료");
      }
    } catch (error) {
      console.error("FCM 토큰 처리 중 오류 발생:", error);
      alert(
        "푸시 알림 설정에 오류가 발생하였습니다. 프로필 설정에서 다시 시도해주세요."
      );
    }
  }
};
